import { default as admins6XzpQeOEwaMeta } from "/tmp/build_86721156/pages/administration/admins.vue?macro=true";
import { default as _91id_93sWrfNN7rwXMeta } from "/tmp/build_86721156/pages/administration/billing/[id].vue?macro=true";
import { default as indexHUMiAgYFMjMeta } from "/tmp/build_86721156/pages/administration/billing/index.vue?macro=true";
import { default as bookingsy19PnybFlrMeta } from "/tmp/build_86721156/pages/administration/bookings.vue?macro=true";
import { default as exports3KHsgeBO3RMeta } from "/tmp/build_86721156/pages/administration/exports.vue?macro=true";
import { default as fidelityKqDiMQvnaPMeta } from "/tmp/build_86721156/pages/administration/fidelity.vue?macro=true";
import { default as invoicesTAFgSFAwI2Meta } from "/tmp/build_86721156/pages/administration/invoices.vue?macro=true";
import { default as policyWDYIN58fVEMeta } from "/tmp/build_86721156/pages/administration/organization/policy.vue?macro=true";
import { default as _91id_93GefvtvKWH9Meta } from "/tmp/build_86721156/pages/administration/payment/[id].vue?macro=true";
import { default as indexxCCq4j845fMeta } from "/tmp/build_86721156/pages/administration/payment/index.vue?macro=true";
import { default as co2wn2tIkIo0UMeta } from "/tmp/build_86721156/pages/administration/statistics/co2.vue?macro=true";
import { default as indexqj3Fr4Xkp5Meta } from "/tmp/build_86721156/pages/administration/statistics/index.vue?macro=true";
import { default as indexYWWbWwMOWlMeta } from "/tmp/build_86721156/pages/administration/teams/[teamId]/index.vue?macro=true";
import { default as invitationdnak597mq1Meta } from "/tmp/build_86721156/pages/administration/teams/[teamId]/invitation.vue?macro=true";
import { default as indexGiCkV0u4xgMeta } from "/tmp/build_86721156/pages/administration/teams/[teamId]/policy/index.vue?macro=true";
import { default as modifyKSHSyJtEevMeta } from "/tmp/build_86721156/pages/administration/teams/[teamId]/policy/modify.vue?macro=true";
import { default as validatorsEtEKGa1yUAMeta } from "/tmp/build_86721156/pages/administration/teams/[teamId]/validators.vue?macro=true";
import { default as _91teamId_93b0NdpGBzZ5Meta } from "/tmp/build_86721156/pages/administration/teams/[teamId].vue?macro=true";
import { default as indexBuENtYLYHnMeta } from "/tmp/build_86721156/pages/administration/teams/index.vue?macro=true";
import { default as trip_45labelsMXiMMNOEutMeta } from "/tmp/build_86721156/pages/administration/trip-labels.vue?macro=true";
import { default as administrationmKuTSsN51oMeta } from "/tmp/build_86721156/pages/administration.vue?macro=true";
import { default as swileqM4xnjWBCeMeta } from "/tmp/build_86721156/pages/auth/swile.vue?macro=true";
import { default as indexjO0cWAdZJwMeta } from "/tmp/build_86721156/pages/bookings/index.vue?macro=true";
import { default as indexvyoqKuXP0TMeta } from "/tmp/build_86721156/pages/bookings/validator/index.vue?macro=true";
import { default as pending96B1s7ebn7Meta } from "/tmp/build_86721156/pages/bookings/validator/pending.vue?macro=true";
import { default as _91id_93UvQYIl8lkhMeta } from "/tmp/build_86721156/pages/car/[id].vue?macro=true";
import { default as fidelitydYcmE0ErRcMeta } from "/tmp/build_86721156/pages/fidelity.vue?macro=true";
import { default as exchangeShVxX0eoCNMeta } from "/tmp/build_86721156/pages/flight/[id]/exchange.vue?macro=true";
import { default as fares5yzzzrt96WMeta } from "/tmp/build_86721156/pages/flight/[id]/fares.vue?macro=true";
import { default as indexAmI80osXp9Meta } from "/tmp/build_86721156/pages/flight/[id]/index.vue?macro=true";
import { default as forgotXfTDshMiUuMeta } from "/tmp/build_86721156/pages/forgot.vue?macro=true";
import { default as get_45swile_45appPaBmeY9QhRMeta } from "/tmp/build_86721156/pages/get-swile-app.vue?macro=true";
import { default as helpHBwXUjMtKtMeta } from "/tmp/build_86721156/pages/help.vue?macro=true";
import { default as _91id_93TNGXlgYAY6Meta } from "/tmp/build_86721156/pages/hotel/[id].vue?macro=true";
import { default as _91id_937DAQRBjtikMeta } from "/tmp/build_86721156/pages/impersonate/[id].vue?macro=true";
import { default as indexPb3pt3jPwDMeta } from "/tmp/build_86721156/pages/index.vue?macro=true";
import { default as _91suffix_93Pl81nK8hYYMeta } from "/tmp/build_86721156/pages/invitation/[suffix].vue?macro=true";
import { default as _91token_93XX7swlV0PJMeta } from "/tmp/build_86721156/pages/invite/[token].vue?macro=true";
import { default as _91id_93s8YIOkm1Q5Meta } from "/tmp/build_86721156/pages/journeys/[id].vue?macro=true";
import { default as indexvvxF6YPSfSMeta } from "/tmp/build_86721156/pages/journeys/index.vue?macro=true";
import { default as membersZEwFVUCFedMeta } from "/tmp/build_86721156/pages/myteam/members.vue?macro=true";
import { default as policykCVUE51dpvMeta } from "/tmp/build_86721156/pages/myteam/policy.vue?macro=true";
import { default as validatorsLhZxB2WtzEMeta } from "/tmp/build_86721156/pages/myteam/validators.vue?macro=true";
import { default as myteamzIMVV62wY8Meta } from "/tmp/build_86721156/pages/myteam.vue?macro=true";
import { default as new_45userHg94vIGOfJMeta } from "/tmp/build_86721156/pages/new-user.vue?macro=true";
import { default as passwordrdr3GqqY5vMeta } from "/tmp/build_86721156/pages/password.vue?macro=true";
import { default as indexoH3TVtsfEoMeta } from "/tmp/build_86721156/pages/search/car/[search_id]/index.vue?macro=true";
import { default as indexkSDL7WgRDOMeta } from "/tmp/build_86721156/pages/search/car/index.vue?macro=true";
import { default as _91search_id_93ICyrJjSFWsMeta } from "/tmp/build_86721156/pages/search/car/init/[search_id].vue?macro=true";
import { default as _91search_id_93roNqSPWz4tMeta } from "/tmp/build_86721156/pages/search/flight/[search_id].vue?macro=true";
import { default as index7fKwezy8oTMeta } from "/tmp/build_86721156/pages/search/flight/index.vue?macro=true";
import { default as indexREL8XHRm2NMeta } from "/tmp/build_86721156/pages/search/hotel/[search_id]/index.vue?macro=true";
import { default as _91hotelIds_93tXVCkKVxpeMeta } from "/tmp/build_86721156/pages/search/hotel/[search_id]/rooms/[hotelIds].vue?macro=true";
import { default as indexzitnmq4dmhMeta } from "/tmp/build_86721156/pages/search/hotel/index.vue?macro=true";
import { default as _91search_id_93cikqZkLLp3Meta } from "/tmp/build_86721156/pages/search/train/[search_id].vue?macro=true";
import { default as indexTyahsEQrNuMeta } from "/tmp/build_86721156/pages/search/train/index.vue?macro=true";
import { default as searchYncrB07nO0Meta } from "/tmp/build_86721156/pages/search.vue?macro=true";
import { default as index2PlfUhqdYoMeta } from "/tmp/build_86721156/pages/teams/[teamId]/index.vue?macro=true";
import { default as invitationHTSiV3LDB4Meta } from "/tmp/build_86721156/pages/teams/[teamId]/invitation.vue?macro=true";
import { default as policynEIEzsOgQtMeta } from "/tmp/build_86721156/pages/teams/[teamId]/policy.vue?macro=true";
import { default as validatorsVvkrt3obq4Meta } from "/tmp/build_86721156/pages/teams/[teamId]/validators.vue?macro=true";
import { default as _91teamId_93PWvFlT6FXJMeta } from "/tmp/build_86721156/pages/teams/[teamId].vue?macro=true";
import { default as indexmc8Rjjxrm4Meta } from "/tmp/build_86721156/pages/teams/index.vue?macro=true";
import { default as teamsxIvYJnlKzcMeta } from "/tmp/build_86721156/pages/teams.vue?macro=true";
import { default as indexoNE5FKI0H4Meta } from "/tmp/build_86721156/pages/train/[id]/cancel/index.vue?macro=true";
import { default as indexZ5vaRvNLNfMeta } from "/tmp/build_86721156/pages/train/[id]/exchange/changeable-selection/index.vue?macro=true";
import { default as indexgBW6rJDEsEMeta } from "/tmp/build_86721156/pages/train/[id]/exchange/section-selection/index.vue?macro=true";
import { default as indexrnEVfg2je9Meta } from "/tmp/build_86721156/pages/train/[id]/exchange/train-selection/index.vue?macro=true";
import { default as index3kIiFWx7OZMeta } from "/tmp/build_86721156/pages/train/[id]/index.vue?macro=true";
import { default as driver_45licenseoz61R2rPoeMeta } from "/tmp/build_86721156/pages/users/[id]/driver-license.vue?macro=true";
import { default as fidelityStfgOpNmzmMeta } from "/tmp/build_86721156/pages/users/[id]/fidelity.vue?macro=true";
import { default as indexbRVWNDwsOrMeta } from "/tmp/build_86721156/pages/users/[id]/index.vue?macro=true";
import { default as passportdQxuNSQNn3Meta } from "/tmp/build_86721156/pages/users/[id]/passport.vue?macro=true";
import { default as passwordvAp9mSAf8xMeta } from "/tmp/build_86721156/pages/users/[id]/password.vue?macro=true";
import { default as _91id_93wzqmd3JpuGMeta } from "/tmp/build_86721156/pages/users/[id].vue?macro=true";
import { default as index9zNgjMfQodMeta } from "/tmp/build_86721156/pages/users/index.vue?macro=true";
import { default as vouchersteceuYxShbMeta } from "/tmp/build_86721156/pages/vouchers.vue?macro=true";
import { default as component_45stubCCQIzLa5vhMeta } from "/tmp/build_86721156/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubCCQIzLa5vh } from "/tmp/build_86721156/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "administration",
    path: "/administration",
    meta: administrationmKuTSsN51oMeta || {},
    component: () => import("/tmp/build_86721156/pages/administration.vue"),
    children: [
  {
    name: "administration-admins",
    path: "admins",
    component: () => import("/tmp/build_86721156/pages/administration/admins.vue")
  },
  {
    name: "administration-billing-id",
    path: "billing/:id()",
    component: () => import("/tmp/build_86721156/pages/administration/billing/[id].vue")
  },
  {
    name: "administration-billing",
    path: "billing",
    component: () => import("/tmp/build_86721156/pages/administration/billing/index.vue")
  },
  {
    name: "administration-bookings",
    path: "bookings",
    component: () => import("/tmp/build_86721156/pages/administration/bookings.vue")
  },
  {
    name: "administration-exports",
    path: "exports",
    component: () => import("/tmp/build_86721156/pages/administration/exports.vue")
  },
  {
    name: "administration-fidelity",
    path: "fidelity",
    component: () => import("/tmp/build_86721156/pages/administration/fidelity.vue")
  },
  {
    name: "administration-invoices",
    path: "invoices",
    component: () => import("/tmp/build_86721156/pages/administration/invoices.vue")
  },
  {
    name: "administration-organization-policy",
    path: "organization/policy",
    component: () => import("/tmp/build_86721156/pages/administration/organization/policy.vue")
  },
  {
    name: "administration-payment-id",
    path: "payment/:id()",
    component: () => import("/tmp/build_86721156/pages/administration/payment/[id].vue")
  },
  {
    name: "administration-payment",
    path: "payment",
    component: () => import("/tmp/build_86721156/pages/administration/payment/index.vue")
  },
  {
    name: "administration-statistics-co2",
    path: "statistics/co2",
    component: () => import("/tmp/build_86721156/pages/administration/statistics/co2.vue")
  },
  {
    name: "administration-statistics",
    path: "statistics",
    component: () => import("/tmp/build_86721156/pages/administration/statistics/index.vue")
  },
  {
    name: _91teamId_93b0NdpGBzZ5Meta?.name,
    path: "teams/:teamId()",
    component: () => import("/tmp/build_86721156/pages/administration/teams/[teamId].vue"),
    children: [
  {
    name: "administration-teams-teamId",
    path: "",
    meta: indexYWWbWwMOWlMeta || {},
    component: () => import("/tmp/build_86721156/pages/administration/teams/[teamId]/index.vue")
  },
  {
    name: "administration-teams-teamId-invitation",
    path: "invitation",
    component: () => import("/tmp/build_86721156/pages/administration/teams/[teamId]/invitation.vue")
  },
  {
    name: "administration-teams-teamId-policy",
    path: "policy",
    component: () => import("/tmp/build_86721156/pages/administration/teams/[teamId]/policy/index.vue")
  },
  {
    name: "administration-teams-teamId-policy-modify",
    path: "policy/modify",
    meta: modifyKSHSyJtEevMeta || {},
    component: () => import("/tmp/build_86721156/pages/administration/teams/[teamId]/policy/modify.vue")
  },
  {
    name: "administration-teams-teamId-validators",
    path: "validators",
    component: () => import("/tmp/build_86721156/pages/administration/teams/[teamId]/validators.vue")
  }
]
  },
  {
    name: "administration-teams",
    path: "teams",
    component: () => import("/tmp/build_86721156/pages/administration/teams/index.vue")
  },
  {
    name: "administration-trip-labels",
    path: "trip-labels",
    component: () => import("/tmp/build_86721156/pages/administration/trip-labels.vue")
  }
]
  },
  {
    name: "auth-swile",
    path: "/auth/swile",
    meta: swileqM4xnjWBCeMeta || {},
    component: () => import("/tmp/build_86721156/pages/auth/swile.vue")
  },
  {
    name: "bookings",
    path: "/bookings",
    component: () => import("/tmp/build_86721156/pages/bookings/index.vue")
  },
  {
    name: "bookings-validator",
    path: "/bookings/validator",
    meta: indexvyoqKuXP0TMeta || {},
    component: () => import("/tmp/build_86721156/pages/bookings/validator/index.vue")
  },
  {
    name: "bookings-validator-pending",
    path: "/bookings/validator/pending",
    meta: pending96B1s7ebn7Meta || {},
    component: () => import("/tmp/build_86721156/pages/bookings/validator/pending.vue")
  },
  {
    name: "car-id",
    path: "/car/:id()",
    component: () => import("/tmp/build_86721156/pages/car/[id].vue")
  },
  {
    name: "fidelity",
    path: "/fidelity",
    component: () => import("/tmp/build_86721156/pages/fidelity.vue")
  },
  {
    name: "flight-id-exchange",
    path: "/flight/:id()/exchange",
    component: () => import("/tmp/build_86721156/pages/flight/[id]/exchange.vue")
  },
  {
    name: "flight-id-fares",
    path: "/flight/:id()/fares",
    component: () => import("/tmp/build_86721156/pages/flight/[id]/fares.vue")
  },
  {
    name: "flight-id",
    path: "/flight/:id()",
    component: () => import("/tmp/build_86721156/pages/flight/[id]/index.vue")
  },
  {
    name: "forgot",
    path: "/forgot",
    meta: forgotXfTDshMiUuMeta || {},
    component: () => import("/tmp/build_86721156/pages/forgot.vue")
  },
  {
    name: "get-swile-app",
    path: "/get-swile-app",
    meta: get_45swile_45appPaBmeY9QhRMeta || {},
    component: () => import("/tmp/build_86721156/pages/get-swile-app.vue")
  },
  {
    name: "help",
    path: "/help",
    component: () => import("/tmp/build_86721156/pages/help.vue")
  },
  {
    name: "hotel-id",
    path: "/hotel/:id()",
    component: () => import("/tmp/build_86721156/pages/hotel/[id].vue")
  },
  {
    name: "impersonate-id",
    path: "/impersonate/:id()",
    component: () => import("/tmp/build_86721156/pages/impersonate/[id].vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexPb3pt3jPwDMeta || {},
    component: () => import("/tmp/build_86721156/pages/index.vue")
  },
  {
    name: "invitation-suffix",
    path: "/invitation/:suffix()",
    meta: _91suffix_93Pl81nK8hYYMeta || {},
    component: () => import("/tmp/build_86721156/pages/invitation/[suffix].vue")
  },
  {
    name: "invite-token",
    path: "/invite/:token()",
    meta: _91token_93XX7swlV0PJMeta || {},
    component: () => import("/tmp/build_86721156/pages/invite/[token].vue")
  },
  {
    name: "journeys-id",
    path: "/journeys/:id()",
    component: () => import("/tmp/build_86721156/pages/journeys/[id].vue")
  },
  {
    name: "journeys",
    path: "/journeys",
    component: () => import("/tmp/build_86721156/pages/journeys/index.vue")
  },
  {
    name: "myteam",
    path: "/myteam",
    component: () => import("/tmp/build_86721156/pages/myteam.vue"),
    children: [
  {
    name: "myteam-members",
    path: "members",
    component: () => import("/tmp/build_86721156/pages/myteam/members.vue")
  },
  {
    name: "myteam-policy",
    path: "policy",
    component: () => import("/tmp/build_86721156/pages/myteam/policy.vue")
  },
  {
    name: "myteam-validators",
    path: "validators",
    component: () => import("/tmp/build_86721156/pages/myteam/validators.vue")
  }
]
  },
  {
    name: "new-user",
    path: "/new-user",
    meta: new_45userHg94vIGOfJMeta || {},
    component: () => import("/tmp/build_86721156/pages/new-user.vue")
  },
  {
    name: "password",
    path: "/password",
    meta: passwordrdr3GqqY5vMeta || {},
    component: () => import("/tmp/build_86721156/pages/password.vue")
  },
  {
    name: "search",
    path: "/search",
    component: () => import("/tmp/build_86721156/pages/search.vue"),
    children: [
  {
    name: "search-car-search_id",
    path: "car/:search_id()",
    component: () => import("/tmp/build_86721156/pages/search/car/[search_id]/index.vue")
  },
  {
    name: "search-car",
    path: "car",
    component: () => import("/tmp/build_86721156/pages/search/car/index.vue")
  },
  {
    name: "search-car-init-search_id",
    path: "car/init/:search_id()",
    component: () => import("/tmp/build_86721156/pages/search/car/init/[search_id].vue")
  },
  {
    name: "search-flight-search_id",
    path: "flight/:search_id()",
    component: () => import("/tmp/build_86721156/pages/search/flight/[search_id].vue")
  },
  {
    name: "search-flight",
    path: "flight",
    component: () => import("/tmp/build_86721156/pages/search/flight/index.vue")
  },
  {
    name: "search-hotel-search_id",
    path: "hotel/:search_id()",
    component: () => import("/tmp/build_86721156/pages/search/hotel/[search_id]/index.vue")
  },
  {
    name: "search-hotel-search_id-rooms-hotelIds",
    path: "hotel/:search_id()/rooms/:hotelIds()",
    component: () => import("/tmp/build_86721156/pages/search/hotel/[search_id]/rooms/[hotelIds].vue")
  },
  {
    name: "search-hotel",
    path: "hotel",
    component: () => import("/tmp/build_86721156/pages/search/hotel/index.vue")
  },
  {
    name: "search-train-search_id",
    path: "train/:search_id()",
    component: () => import("/tmp/build_86721156/pages/search/train/[search_id].vue")
  },
  {
    name: "search-train",
    path: "train",
    component: () => import("/tmp/build_86721156/pages/search/train/index.vue")
  }
]
  },
  {
    name: teamsxIvYJnlKzcMeta?.name,
    path: "/teams",
    meta: teamsxIvYJnlKzcMeta || {},
    component: () => import("/tmp/build_86721156/pages/teams.vue"),
    children: [
  {
    name: _91teamId_93PWvFlT6FXJMeta?.name,
    path: ":teamId()",
    component: () => import("/tmp/build_86721156/pages/teams/[teamId].vue"),
    children: [
  {
    name: "teams-teamId",
    path: "",
    component: () => import("/tmp/build_86721156/pages/teams/[teamId]/index.vue")
  },
  {
    name: "teams-teamId-invitation",
    path: "invitation",
    component: () => import("/tmp/build_86721156/pages/teams/[teamId]/invitation.vue")
  },
  {
    name: "teams-teamId-policy",
    path: "policy",
    component: () => import("/tmp/build_86721156/pages/teams/[teamId]/policy.vue")
  },
  {
    name: "teams-teamId-validators",
    path: "validators",
    component: () => import("/tmp/build_86721156/pages/teams/[teamId]/validators.vue")
  }
]
  },
  {
    name: "teams",
    path: "",
    component: () => import("/tmp/build_86721156/pages/teams/index.vue")
  }
]
  },
  {
    name: "train-id-cancel",
    path: "/train/:id()/cancel",
    component: () => import("/tmp/build_86721156/pages/train/[id]/cancel/index.vue")
  },
  {
    name: "train-id-exchange-changeable-selection",
    path: "/train/:id()/exchange/changeable-selection",
    component: () => import("/tmp/build_86721156/pages/train/[id]/exchange/changeable-selection/index.vue")
  },
  {
    name: "train-id-exchange-section-selection",
    path: "/train/:id()/exchange/section-selection",
    component: () => import("/tmp/build_86721156/pages/train/[id]/exchange/section-selection/index.vue")
  },
  {
    name: "train-id-exchange-train-selection",
    path: "/train/:id()/exchange/train-selection",
    component: () => import("/tmp/build_86721156/pages/train/[id]/exchange/train-selection/index.vue")
  },
  {
    name: "train-id",
    path: "/train/:id()",
    component: () => import("/tmp/build_86721156/pages/train/[id]/index.vue")
  },
  {
    name: _91id_93wzqmd3JpuGMeta?.name,
    path: "/users/:id()",
    meta: _91id_93wzqmd3JpuGMeta || {},
    component: () => import("/tmp/build_86721156/pages/users/[id].vue"),
    children: [
  {
    name: "users-id-driver-license",
    path: "driver-license",
    component: () => import("/tmp/build_86721156/pages/users/[id]/driver-license.vue")
  },
  {
    name: "users-id-fidelity",
    path: "fidelity",
    component: () => import("/tmp/build_86721156/pages/users/[id]/fidelity.vue")
  },
  {
    name: "users-id",
    path: "",
    component: () => import("/tmp/build_86721156/pages/users/[id]/index.vue")
  },
  {
    name: "users-id-passport",
    path: "passport",
    component: () => import("/tmp/build_86721156/pages/users/[id]/passport.vue")
  },
  {
    name: "users-id-password",
    path: "password",
    component: () => import("/tmp/build_86721156/pages/users/[id]/password.vue")
  }
]
  },
  {
    name: "users",
    path: "/users",
    component: () => import("/tmp/build_86721156/pages/users/index.vue")
  },
  {
    name: "vouchers",
    path: "/vouchers",
    component: () => import("/tmp/build_86721156/pages/vouchers.vue")
  },
  {
    name: "teams-vouchers",
    path: "/teams-vouchers",
    component: () => import("/tmp/build_86721156/pages/vouchers.vue")
  },
  {
    name: component_45stubCCQIzLa5vhMeta?.name,
    path: "/metrics",
    component: component_45stubCCQIzLa5vh
  },
  {
    name: component_45stubCCQIzLa5vhMeta?.name,
    path: "/en",
    component: component_45stubCCQIzLa5vh
  },
  {
    name: component_45stubCCQIzLa5vhMeta?.name,
    path: "/fr",
    component: component_45stubCCQIzLa5vh
  },
  {
    name: component_45stubCCQIzLa5vhMeta?.name,
    path: "/pt-BR",
    component: component_45stubCCQIzLa5vh
  },
  {
    name: component_45stubCCQIzLa5vhMeta?.name,
    path: "/en/:pathMatch(.*)",
    component: component_45stubCCQIzLa5vh
  },
  {
    name: component_45stubCCQIzLa5vhMeta?.name,
    path: "/fr/:pathMatch(.*)",
    component: component_45stubCCQIzLa5vh
  },
  {
    name: component_45stubCCQIzLa5vhMeta?.name,
    path: "/pt-BR/:pathMatch(.*)",
    component: component_45stubCCQIzLa5vh
  }
]